/* Jobstimme styles */

@import "../colors";
@import "../settings";

.kmta__tenant--jobstimme {
    .kmta__stepsLogo .jobstimme__logo {
        box-shadow: none;
    }

    .kmta__faqHome {
        background: linear-gradient(
            to bottom,
            rgba(236, 237, 237, 1) 0%,
            rgba(198, 199, 200, 1) 100%
        );
    }

    .dropdown.menu li.active > a,
    .menu.is-dropdown-submenu
        li.is-submenu-item.is-dropdown-submenu-item.active
        > a {
        font-weight: $global-weight-bold;
    }

    .dropdown.menu .is-active > a,
    .kmta__menu li.is-submenu-item a:hover {
        color: var(--nav-item-hover-color);
    }

    @include breakpoint(small down) {
        .kmta__headerTop {
            padding: 0.4rem;
        }
        .kmta__headerUserinfo {
            margin-top: 0;
        }
        .kmta__headerBottomRow {
            justify-content: space-between;
        }
        a.jobstimme__logo {
            width: 75%;
            height: max-content;
            transform: translateY(0px);
            padding-bottom: 0.5rem;
            .jobstimme_svgIcon--logo {
                width: 100%;
                height: auto;
            }
        }
    }
}
